import React, { useState, useEffect } from "react";
import classes from "./portfolio_preview.css";

import left_arrow from "../../../assets/portfolio/icons/left_arrow.png";
import right_arrow from "../../../assets/portfolio/icons/right_arrow.png";

const PortfolioPreview = props => {
  useEffect(() => {
    if (props.hide) {
      set_active_image(0); //not optimised right
    }
  });

  const [active_image, set_active_image] = useState(0);

  const default_style = {
    backgroundImage: "url(" + props.data.cover + ")",
    transform: "TranslateX(0)"
  };

  const [first_image_style, set_first_image_style] = useState(default_style);

  const image_container_classes_temp = [
    classes.image_container,
    classes.auto_scroll
  ];
  const [image_container_classes, set_image_container_classes] = useState(
    image_container_classes_temp
  );

  let preview_window_classes = [classes.preview_window];
  if (props.hide) {
    preview_window_classes.push(classes.hidden);
  }

  const previous_image = () => {
    const img_position = 50 * (active_image - 1);

    const style_copy = { ...first_image_style };
    style_copy.transform = "TranslateX(-" + img_position + "vw)";

    set_first_image_style(style_copy);
    set_active_image(active_image - 1);
  };

  const next_image = () => {
    const img_position = 50 * (active_image + 1);

    const style_copy = { ...first_image_style };
    style_copy.transform = "TranslateX(-" + img_position + "vw)";

    set_first_image_style(style_copy);
    set_active_image(active_image + 1);
  };

  const stop_auto_scrolling = () => {
    console.log("stopping");
    set_image_container_classes([classes.image_container]); // not optimised
  };

  let previous_button = null;
  if (active_image > 0) {
    previous_button = (
      <div
        className={[classes.previous, classes.image_button].join(" ")}
        onClick={previous_image}
      >
        <img className={classes.arrow} src={left_arrow} alt="" />
      </div>
    );
  }

  let next_button = null;
  if (active_image < props.data.image_list.length - 1) {
    next_button = (
      <div
        className={[classes.next, classes.image_button].join(" ")}
        onClick={next_image}
      >
        <img className={classes.arrow} src={right_arrow} alt="" />
      </div>
    );
  }

  return (
    <div className={preview_window_classes.join(" ")}>
      <div
        className={classes.image_scroll_container}
        onScroll={stop_auto_scrolling}
      >
        {props.data.image_list.map((image, index) => {
          if (index === 0) {
            const img_position = 50 * active_image;

            const first_image_style = {
              backgroundImage: "url(" + image + ")",
              transform: "TranslateX(-" + img_position + "vw)"
            };

            return (
              <div
                className={image_container_classes.join(" ")}
                style={first_image_style}
                key={"img" + index}
              />
            );
          } else {
            const img_position = 50 * active_image;

            const image_style = {
              backgroundImage: "url(" + image + ")",
              transform: "TranslateX(-" + img_position + "vw)"
            };

            return (
              <div
                className={image_container_classes.join(" ")}
                style={image_style}
                key={"img" + index}
              />
            );
          }
        })}

        <div className={classes.button_holder}>
          {previous_button}
          {next_button}
        </div>
      </div>

      <div className={classes.text_container}>
        <span className={classes.heading}>{props.data.name}</span>
        <span className={classes.subheading}>{props.data.subtitle}</span>
        <hr />
        <p>{props.data.description}</p>
      </div>

      <div className={classes.button_container}>
        <button className={classes.visit_site}>
          <a href={props.data.link} target="_blank" rel="noopener noreferrer">
            Visit Site &#10162;
          </a>
        </button>
      </div>
    </div>
  );
};

export default PortfolioPreview;
