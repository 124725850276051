import React, { Component } from "react";

import Aux from "../../hoc/Aux";

import Summary from "../../containers/summary/summary";
import Timeline from "../../containers/timeline/timeline";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.scroll_to_timeline = React.createRef();
    this.my_story_button_handler = () => {
      window.scrollTo(0, this.scroll_to_timeline.current.offsetTop);
    };
  }

  render() {
    return (
      <Aux>
        <Summary my_story_button_clicked={this.my_story_button_handler} />

        <Timeline
          timeline_ref={this.scroll_to_timeline}
          scroll_to_portfolio={this.props.scroll_to_portfolio}
        />
      </Aux>
    );
  }
}

export default Homepage;
