import React from "react";
import classes from "./scroll_to_top.css";

import arrow_img from "../../assets/scroll_to_top/arrow.png";

const ScrollToTop = props => {
  return props.show ? (
    <div className={classes.scroll_to_top} onClick={props.scroll_to_top}>
      <img src={arrow_img} className={classes.arrow} alt="" />
    </div>
  ) : (
    <div
      className={[classes.scroll_to_top, classes.hidden].join(" ")}
      onClick={props.scroll_to_top}
    >
      <img src={arrow_img} className={classes.arrow} alt="" />
    </div>
  );
};

export default ScrollToTop;
