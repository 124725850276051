import React from "react";
//import classes from './layout.css';

import Aux from "../../hoc/Aux";
import Navbar from "./navbar/navbar";
import Footer from "./footer/footer";

const Layout = props => {
  return (
    <Aux>
      <Navbar scroll_to_portfolio={props.scroll_to_portfolio} />
      <main>{props.children}</main>
      <Footer />
    </Aux>
  );
};

export default Layout;
