import React, {Component} from "react";

import classes from "./summary.css";

import Aux from "../../hoc/Aux";

import profile_pic from "./../../assets/summary/profile_pic.jpg";
import three_lines from "./../../assets/summary/three_lines.png";
import arrow from "./../../assets/summary/arrow.png";

import cv_icon from "./../../assets/summary/cv_icon.png";
import linkedin_icon from "./../../assets/summary/linkedin_icon.png";
import email_icon from "./../../assets/summary/email_icon.png";
import github_icon from "./../../assets/summary/github_icon.svg";

import cv_pdf from "./../../assets/summary/cv.pdf";

class Summary extends Component {
  render() {
    return (
      <Aux>
        <div className={classes.main_details_container}>
          <h5 className={classes.subtle_text}>
            I do my best to consistency embody...
          </h5>
          <h1>tech competency | architecture | communication</h1>
          <img
            className={classes.profile_pic}
            src={profile_pic}
            alt=""
            onClick={this.props.my_story_button_clicked}
          />
          <div className={classes.icon_container}>
            <a
              href="https://www.linkedin.com/in/scott-fraser-3a422348/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={classes.icon} src={linkedin_icon} alt="" />
            </a>
            <a href={cv_pdf} target="_blank" rel="noopener noreferrer">
              <img className={classes.icon} src={cv_icon} alt="" />
            </a>
            <a href="mailto:ScottJFraser@googlemail.com?subject=Hi Scott&body=Scott,">
              <img className={classes.icon} src={email_icon} alt="" />
            </a>
            <a
              href="https://github.com/SF92"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={classes.icon} src={github_icon} alt="" />
            </a>
          </div>

          <h2>Scott Fraser</h2>
          <h3>Senior Backend Engineer</h3>
          <h3 className={classes.light}>heycar uk</h3>

          <img className={classes.three_lines} src={three_lines} alt="" />

          <div
            className={classes.my_story_button}
            onClick={this.props.my_story_button_clicked}
          >
            <h4 className={classes.pulse}>My Story</h4>
            <img
              className={[classes.arrow, classes.pulse].join(" ")}
              src={arrow}
              alt=""
            />
          </div>
        </div>
      </Aux>
    );
  }
}

export default Summary;
