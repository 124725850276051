import React from "react";
import classes from "./navbar.css";

import logo from "../../../assets/navbar/logo.png";

const Navbar = props => {
  return (
    <div className={classes.navbar}>
      <img className={classes.logo} src={logo} alt="" />
      <a
        className={classes.nav_item}
        href="https://medium.com/@ScottJFraser"
        target="_blank"
        rel="noopener noreferrer"
      >
        Articles
      </a>
    </div>
  );
};

export default Navbar;

/* 
<span className={classes.nav_item} onClick={props.scroll_to_portfolio}>
   Portfolio
</span>

*/
