import React, {useState} from "react";
import {useSwipeable} from "react-swipeable";

import classes from "./timeline.css";

import Aux from "../../hoc/Aux";

// ORGANISATION LOGOS
import hsog_logo from "../../assets/timeline/organisation_logos/hsog_logo.png";
import imperial_logo from "../../assets/timeline/organisation_logos/imperial_logo.png";
import sot_logo from "../../assets/timeline/organisation_logos/sot_logo.png";
import exxon_logo from "../../assets/timeline/organisation_logos/exxon_logo.png";
import token_track_logo from "../../assets/timeline/organisation_logos/token_track_logo.png";
import migacore_logo from "../../assets/timeline/organisation_logos/migacore_logo.png";
import luberly_logo from "../../assets/timeline/organisation_logos/luberly_logo.png";
import heycar_logo from "../../assets/timeline/organisation_logos/heycar_logo.jpg";

// SKILLS
import Skill from "./skill/skill";

import french_logo from "../../assets/timeline/skill_logos/french_logo.png";
import maths_logo from "../../assets/timeline/skill_logos/maths_logo.png";
import physics_logo from "../../assets/timeline/skill_logos/physics_logo.png";
import english_logo from "../../assets/timeline/skill_logos/english_logo.png";
import chemistry_logo from "../../assets/timeline/skill_logos/chemistry_logo.png";

import statistics_logo from "../../assets/timeline/skill_logos/statistics_logo.png";
import mechanics_logo from "../../assets/timeline/skill_logos/mechanics_logo.png";
import matlab_logo from "../../assets/timeline/skill_logos/matlab_logo.png";
import fourier_logo from "../../assets/timeline/skill_logos/fourier_analysis.png";

import data_analysis_logo from "../../assets/timeline/skill_logos/data_analysis_logo.png";
import vba_logo from "../../assets/timeline/skill_logos/vba_logo.png";
import communication_logo from "../../assets/timeline/skill_logos/communication_logo.png";
import mech_eng_logo from "../../assets/timeline/skill_logos/mech_eng_logo.png";
import python_logo from "../../assets/timeline/skill_logos/python_logo.png";

import automation_logo from "../../assets/timeline/skill_logos/automation_logo.png";
import project_management_logo from "../../assets/timeline/skill_logos/project_management_logo.png";

import django_logo from "../../assets/timeline/skill_logos/django_logo.png";
import python_anywhere_logo from "../../assets/timeline/skill_logos/python_anywhere_logo.png";
import javascript_logo from "../../assets/timeline/skill_logos/javascript_logo.png";
import jquery_logo from "../../assets/timeline/skill_logos/jquery_logo.png";
import ajax_logo from "../../assets/timeline/skill_logos/ajax_logo.png";

import sales_logo from "../../assets/timeline/skill_logos/sales_logo.png";
import jupyter_logo from "../../assets/timeline/skill_logos/jupyter_logo.png";

import aws_logo from "../../assets/timeline/skill_logos/aws_logo.png";

import gcp_logo from "../../assets/timeline/skill_logos/gcp_logo.png";
import flask_logo from "../../assets/timeline/skill_logos/flask_logo.jpg";
import ts_logo from "../../assets/timeline/skill_logos/ts_logo.png";
import k8s_logo from "../../assets/timeline/skill_logos/k8s_logo.jpg";

import fast_api_logo from "../../assets/timeline/skill_logos/fast_api_logo.png";

// TIMELINE
import arrow from "../../assets/summary/arrow.png";

// TIMELINE MAP
import education_icon from "../../assets/timeline/timeline_map_icons/education_icon.png";
import education_icon_active from "../../assets/timeline/timeline_map_icons/education_icon_active.png";
import work_icon from "../../assets/timeline/timeline_map_icons/work_icon.png";
import work_icon_active from "../../assets/timeline/timeline_map_icons/work_icon_active.png";
import personal_project from "../../assets/timeline/timeline_map_icons/personal_project.png";
import personal_project_active from "../../assets/timeline/timeline_map_icons/personal_project_active.png";
import business_project from "../../assets/timeline/timeline_map_icons/business_project.png";
import business_project_active from "../../assets/timeline/timeline_map_icons/business_project_active.png";

import forward_arrow_img from "../../assets/timeline/timeline_map_icons/forward_arrow.png";
import back_arrow_img from "../../assets/timeline/timeline_map_icons/back_arrow.png";
import point_in_time from "../../assets/timeline/timeline_map_icons/point_in_time.png";
import active_point_in_time_icon from "../../assets/timeline/timeline_map_icons/active_point_in_time.png";

// PREVIEW WINDOW

import date_icon from "../../assets/timeline/side_icons/date_icon.png";
import location_icon from "../../assets/timeline/side_icons/location_icon.png";
import organisation_icon from "../../assets/timeline/side_icons/organisation_icon.png";
import subtitle_icon from "../../assets/timeline/side_icons/subtitle_icon.png";

const Timeline = props => {
  const points_in_time = [
    {
      title: "The High School of Glasgow",
      start_year: "2003",
      title_logo: {
        active: education_icon_active,
        inactive: education_icon
      },
      subtitle: "Student",
      organisation: "HSOG",
      organisation_logo: hsog_logo,
      location: "Glasgow, Scotland",
      date: "Sept 2003 - June 2010",
      skills: [
        {
          text: "Maths",
          logo: maths_logo
        },
        {
          text: "English",
          logo: english_logo
        },
        {
          text: "Physics",
          logo: physics_logo
        },
        {
          text: "Chemistry",
          logo: chemistry_logo
        },
        {
          text: "French",
          logo: french_logo
        }
      ],
      description: (
        <div>
          <p>School is where I discovered my love of science and technology.</p>
          <p>
            Throughout my time at secondary school I elected to study as many
            technical subjects as possible. This culminated in the three
            Advanced Higher qualifications in Maths, Physics and Chemistry
            required to study engineering at Imperial College London.
          </p>
          <p>
            <strong>Advanced Highers:</strong>
          </p>
          <p>Maths A; Physics A; Chemistry A</p>
          <p>
            <strong>Highers:</strong>
          </p>
          <p> Maths A; Physics A; Chemistry A; French A; English A</p>
        </div>
      )
    },
    {
      title: "Imperial College London",
      start_year: "2010",
      title_logo: {
        active: education_icon_active,
        inactive: education_icon
      },
      subtitle: "Mechanical Engineering",
      organisation: "Imperial College London",
      organisation_logo: imperial_logo,
      location: "London, UK",
      date: "Oct 2010 - June 2014",
      skills: [
        {
          text: "Maths",
          logo: maths_logo
        },
        {
          text: "Statistics",
          logo: statistics_logo
        },
        {
          text: "Fourier Analysis",
          logo: fourier_logo
        },
        {
          text: "Mechanics",
          logo: mechanics_logo
        },
        {
          text: "MATLAB",
          logo: matlab_logo
        }
      ],
      description: (
        <div>
          <p>
            In 2014 I was awarded a Masters of Engineering from Imperial College
            London.
          </p>
          <p>
            <strong>Course Overview</strong>
          </p>
          <p>
            This degree course focused on the mathematical underpinnings of
            Mechanical Engineering and provided the strong technical foundation
            I have used extensively throughout my career.
          </p>
          <p>
            <strong>Final Year Project</strong>
          </p>
          <p>
            My final year Masters project was titled "Piezoelectric Paint
            Transducers" and aimed to investigate the effects of temperature and
            time on the performance of paint-on piezoelectric transducers. This
            project relied heavily on an understanding of machine system
            dynamics and Fourier-based signal filtering techniques using MATLAB.
          </p>
          <p />
        </div>
      )
    },
    {
      title: "ExxonMobil",
      start_year: "2014",
      title_logo: {
        active: work_icon_active,
        inactive: work_icon
      },
      subtitle: "Full Stack Developer",
      organisation: "ExxonMobil",
      organisation_logo: exxon_logo,
      location: "London, UK",
      date: "Sept 2014 - Sept 2016",
      skills: [
        {
          text: "Data Analysis",
          logo: data_analysis_logo
        },
        {
          text: "VBA",
          logo: vba_logo
        },
        {
          text: "Python",
          logo: python_logo
        },
        {
          text: "Communication",
          logo: communication_logo
        },
        {
          text: "Mech Eng",
          logo: mech_eng_logo
        }
      ],
      description: (
        <div>
          <p>I joined ExxonMobil as a graduate engineer in September 2014.</p>
          <p>
            My first role was managing the day-to-day operations of a data
            analytics service provided to ExxonMobil's Marine Lubricant
            customers. The service centered around analysing used oil testing
            data to reduce the operating costs of large container ships and oil
            tankers.
          </p>
          <p>
            In addition to a high volume of data analysis I was in daily
            communication with maritime engineers across the globe to both
            deliver the service and seek feedback to improve it.
          </p>
          <p>
            This role opened my eyes to the possibilities of using software
            development to solve business problems. In addition to my daily
            responsibilities I built a suite of Python and VBA reporting tools
            to convince management to completely change their pricing structure
            which significantly increased the profitibility of the service.
          </p>
        </div>
      )
    },
    {
      title: "ExxonMobil",
      start_year: "2016",
      title_logo: {
        active: work_icon_active,
        inactive: work_icon
      },
      subtitle: "Automation Project Lead",
      organisation: "ExxonMobil",
      organisation_logo: exxon_logo,
      location: "London, UK",
      date: "Sept 2016 - Sept 2017",
      skills: [
        {
          text: "Data Analysis",
          logo: data_analysis_logo
        },
        {
          text: "Python",
          logo: python_logo
        },
        {
          text: "Automation",
          logo: automation_logo
        },
        {
          text: "Project Mgmt",
          logo: project_management_logo
        },
        {
          text: "Mech Eng",
          logo: mech_eng_logo
        }
      ],
      description: (
        <div>
          <p>
            In 2016 I was granted resource and appointed Special Projects
            Advisor to design and implement an advanced automation layer for the
            same data analytics service (see portfolio section for more info).
          </p>
          <p>
            During this project I designed an automation algorithm to deliver
            instant oil analysis reporting to deep-sea marine vessels. With my
            extended team we safely transitioned this algorithm to production
            where it now runs 95% of all data packages without the need for
            human interaction.
          </p>
          <p>
            The outcome of this project was and remains a $800k per year saving
            for the corporation and is the backbone of a service which generates
            upwards of $1m per annum.
          </p>
          <p>
            Throughout this project I was co-located between London, Houston and
            Boston where I worked with a number of stakeholders to deliver this
            end-to-end solution.
          </p>
        </div>
      )
    },
    {
      title: "Building SuccessOnTap.io",
      start_year: "2016",
      title_logo: {
        active: business_project_active,
        inactive: business_project
      },
      subtitle: "Full Stack Web Developer",
      organisation: "SuccessOnTap",
      organisation_logo: sot_logo,
      location: "London, UK",
      date: "May 2016 - present",
      skills: [
        {
          text: "Django",
          logo: django_logo
        },
        {
          text: "Python Anywhere",
          logo: python_anywhere_logo
        },
        {
          text: "JavaScript",
          logo: javascript_logo
        },
        {
          text: "JQuery",
          logo: jquery_logo
        },
        {
          text: "Ajax",
          logo: ajax_logo
        }
      ],
      description: (
        <div>
          <p>
            With my day job very focused on data processing algorithms I wanted
            to build my web development skills so created a business aimed at
            helping people achieve their personal goals.
          </p>
          <p>
            SuccessOnTap is a web application which acts as a personal goal
            management system and allows users to set and track their goals
            across a number of timelines.
          </p>
          <p>
            <strong>Tech Stack</strong>
          </p>
          <p>
            My tech stack was Python on the backend (Django) with jQuery on the
            front end all hosted on PythonAnywhere. I also built out an API for
            the iOS app using Django Rest Framework.
          </p>
          <p>
            There are also a significant number of hourly and daily background
            jobs using Python and SQL (mysqlclient). The app leverages
            integrations with Stripe, Slack, Mailchimp and SendGrid.
          </p>
        </div>
      )
    },
    {
      title: "ExxonMobil",
      start_year: "2017",
      title_logo: {
        active: work_icon_active,
        inactive: work_icon
      },
      subtitle: "Field Engineer",
      organisation: "ExxonMobil",
      organisation_logo: exxon_logo,
      location: "London, UK",
      date: "Sept 2017 - June 2019",
      skills: [
        {
          text: "Sales",
          logo: sales_logo
        },
        {
          text: "Python",
          logo: python_logo
        },
        {
          text: "Jupyter Nb",
          logo: jupyter_logo
        },
        {
          text: "Mech Eng",
          logo: mech_eng_logo
        },
        {
          text: "Communication",
          logo: communication_logo
        }
      ],
      description: (
        <div>
          <p>
            In Sept 2017 I was asked to join the ExxonMobil Industrial
            Lubricants sales team as a Sales Engineer. This role involved
            developing a blend of commercial and technical skills to help sell
            our offer to the UK market.
          </p>
          <p>
            My primary role was to understand customer needs from which I could
            develop and offer value-adding technical solutions. In addition to
            winning a number of large accounts I also continued to hone the
            analytical and software engineering skills developed in my previous
            roles.
          </p>
          <p>
            To increase the rate at which I could identify customer pain-points
            I developed a number of Jupyter notebooks which process customer oil
            analysis data to identify any inefficiencies in their operations
            where we could provide a technical solution.
          </p>
          <p>
            I also developed Jupyter notebook using pandas and numpy to process
            high volumes of data from manufacturing distributed control systems
            to identify and quantify energy efficiency benefits associated with
            using energy efficient oils.
          </p>
        </div>
      )
    },
    {
      title: "Building TokenTrack.xyz",
      start_year: "2019",
      title_logo: {
        active: personal_project_active,
        inactive: personal_project
      },
      subtitle: "Full Stack Web Developer",
      organisation: "TokenTrack.xyz",
      organisation_logo: token_track_logo,
      location: "London, UK",
      date: "Feb 2019 - present",
      skills: [
        {
          text: "React",
          logo: physics_logo
        },
        {
          text: "AWS",
          logo: aws_logo
        },
        {
          text: "Python",
          logo: python_logo
        },
        {
          text: "JavaScript",
          logo: javascript_logo
        },
        {
          text: "Django",
          logo: django_logo
        }
      ],
      description: (
        <div>
          <p>
            While working in technical sales for ExxonMobil I was keen to learn
            keep my web development skills sharp so, in my spare time, I decided
            to learn the popular JavaScript framework, React.
          </p>
          <p>
            I created TokenTrack as tool to track the current value of my
            cryptocurrency portfolio which is spread across a number of wallets
            and exchanges.
          </p>
          <p>
            I built the front end in React which uses axios to query an API I
            built using the Django Rest Framework.
          </p>
          <p>
            The App is entirely hosted on AWS making use of Amazon S3 to store
            the static React code which is delivered over https via a cloudfront
            distribution. The API is hosted as a Django app using Amazon Elastic
            Beanstalk connected to an AWS RDS database.
          </p>
          <p>
            To save on operating costs the app fetches market prices from the
            CoinMarketCap API every 15 minutes using AWS cloudwatch events
            triggering a number of AWS Lambda functions to run these fetches.
          </p>
        </div>
      )
    },
    {
      title: "Migacore Technologies",
      start_year: "2019",
      title_logo: {
        active: work_icon_active,
        inactive: work_icon
      },
      subtitle: "Software Engineer",
      organisation: "Migacore Technologies",
      organisation_logo: migacore_logo,
      location: "London, UK",
      date: "Oct 2019 - May 2020",
      skills: [
        {
          text: "React",
          logo: physics_logo
        },
        {
          text: "GCP",
          logo: gcp_logo
        },
        {
          text: "Python",
          logo: python_logo
        },
        {
          text: "Flask",
          logo: flask_logo
        },
        {
          text: "Kubernetes",
          logo: k8s_logo
        },
        {
          text: "TypeScript",
          logo: ts_logo
        },
        {
          text: "JavaScript",
          logo: javascript_logo
        }
      ],
      description: (
        <div>
          <p>
            Headed up development of a product which delivers demand forecasts
            as a result of online contextual data. This role spans the entire
            stack from the TypeScript + React frontend, RubyOnRails middleware,
            multiple Python (Flask) microservices interfacing with a MongoDB all
            run in a Kubernetes cluster.
          </p>
          <p>
            I was also involved in the data engineering side optimising the data
            pipelines which feed the application.
          </p>
          <p>
            <strong>Key tech:</strong> GCP, Python, Docker, Kubernetes (GKE),
            Flask, MongoDB, React, Typescript, RubyOnRails, Redis, GitLabCI.
          </p>
        </div>
      )
    },
    {
      title: "Luberly (side hustle)",
      start_year: "2019",
      title_logo: {
        active: business_project_active,
        inactive: business_project
      },
      subtitle: "Software Engineer",
      organisation: "Jascro Technologies",
      organisation_logo: luberly_logo,
      location: "London, UK",
      date: "Oct 2019 - Present",
      skills: [
        {
          text: "React",
          logo: physics_logo
        },
        {
          text: "GCP",
          logo: gcp_logo
        },
        {
          text: "Python",
          logo: python_logo
        },
        {
          text: "Django",
          logo: django_logo
        },
        {
          text: "Kubernetes",
          logo: k8s_logo
        },
        {
          text: "JavaScript",
          logo: javascript_logo
        }
      ],
      description: (
        <div>
          <p>
            Luberly is modern lubrication software which brings clarity and
            structure to industrial sites lubrication and maintenance practices.
            As a team of four working on this part-time project I head up the
            development of both the frontend (React) and backend (Django +
            FastAPI) backend whilst also mentoring two junior engineers.
          </p>
          <p>
            The backend stack runs in a kubernetes cluster on GKE with a django
            app being the primary web interface and a dedicated FastAPI +
            Elasticsearch service responsible for search. In addition there are
            many small worker pods responsible for reading messages from redis
            and performing asynchronous tasks.
          </p>
          <p>
            <strong>Key tech:</strong> Python, Docker, Kubernetes, React,
            JavaScript, Django, FastAPI, PostgreSQL, Elasticsearch, GitLabCI,
            Redis, GCP
          </p>
        </div>
      )
    },
    {
      title: "heycar uk",
      start_year: "2020",
      title_logo: {
        active: work_icon_active,
        inactive: work_icon
      },
      subtitle: "Senior Backend Engineer",
      organisation: "heycar uk (contract)",
      organisation_logo: heycar_logo,
      location: "London, UK",
      date: "May 2020 - Present",
      skills: [
        {
          text: "Python",
          logo: python_logo
        },
        {
          text: "Django",
          logo: django_logo
        },
        {
          text: "Kubernetes",
          logo: k8s_logo
        },
        {
          text: "FastAPI",
          logo: fast_api_logo
        },
        {
          text: "AWS",
          logo: aws_logo
        }
      ],
      description: (
        <div>
          <p>
            Part of a team maintaining and building the backend infrastructure
            that powers heycar to guide over 1 million weekly visitors through
            their online car-buying journey. The role is centered around
            collaboratively developing and executing on a strategy to scale
            heycar’s backend services to meet their ambitious growth objectives.
          </p>
          <p>
            Much of this work was splitting up a monolithic application into
            well-separated, and thus far more manageable, microservices all
            whilst keeping the lights on and extending existing capabilities.
          </p>
          <p>
            <strong>Key tech:</strong> AWS, Python, Docker, Kubernetes (deployed
            with Helm on EKS), Django, FastAPI, PostgreSQL, Elasticsearch,
            CircleCI
          </p>
        </div>
      )
    }
  ];
  const [active_point_in_time, set_active_point_in_time] = useState(9);
  const [info_container_classes, set_info_container_classes] = useState([
    classes.info_container
  ]);

  const timeline_previous = () => {
    if (active_point_in_time === 0) {
      return;
    }

    set_info_container_classes([classes.info_container, classes.slide_out]);
    const previous_point_in_time = active_point_in_time - 1;

    setTimeout(() => {
      set_active_point_in_time(previous_point_in_time);
      set_info_container_classes([classes.info_container]);
    }, 500);
  };

  const timeline_next = () => {
    if (active_point_in_time === points_in_time.length - 1) {
      return;
    }
    set_info_container_classes([classes.info_container, classes.slide_out]);

    setTimeout(() => {
      set_active_point_in_time(active_point_in_time + 1);
      set_info_container_classes([classes.info_container]);
    }, 500);
  };

  const timeline_stop_clicked = index => {
    set_info_container_classes([classes.info_container, classes.slide_out]);

    setTimeout(() => {
      set_active_point_in_time(index);
      set_info_container_classes([classes.info_container]);
    }, 500);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: timeline_next,
    onSwipedRight: timeline_previous
  });

  // conditionally show arrows

  let back_arrow = (
    <img
      className={classes.arrow}
      src={back_arrow_img}
      onClick={timeline_previous}
      alt=""
    />
  );
  if (active_point_in_time === 0) {
    back_arrow = (
      <img
        className={[classes.arrow, classes.hidden].join(" ")}
        src={back_arrow_img}
        onClick={timeline_previous}
        alt=""
      />
    );
  }

  let next_arrow = (
    <img
      className={classes.arrow}
      src={forward_arrow_img}
      onClick={timeline_next}
      alt=""
    />
  );
  if (active_point_in_time === points_in_time.length - 1) {
    next_arrow = (
      <img
        className={[classes.arrow, classes.hidden].join(" ")}
        src={forward_arrow_img}
        onClick={timeline_next}
        alt=""
      />
    );
  }

  const connector_width = String(75 / (points_in_time.length - 1)) + "vw";
  let connector_style = {
    width: connector_width
  };

  return (
    <div className={classes.timeline_section} ref={props.timeline_ref}>
      <div className={classes.timeline_map}>
        {back_arrow}
        {points_in_time.map((time_point, index) => {
          let point = null;
          //let connector = null
          let title_class = null;

          let connector_classes = [classes.connector, classes.going_forward];
          let logo = (
            <img
              className={[classes.logo].join(" ")}
              src={time_point.title_logo.active}
              alt=""
            />
          );

          if (index === 0) {
            connector_classes.push(classes.display_none);
            if (index === active_point_in_time) {
              point = (
                <img
                  className={[classes.point_in_time, classes.active].join(" ")}
                  src={active_point_in_time_icon}
                  alt=""
                />
              );
              title_class = classes.active;
            } else {
              point = (
                <img
                  className={[
                    classes.point_in_time,
                    classes.rotate_clockwise,
                    classes.active
                  ].join(" ")}
                  src={active_point_in_time_icon}
                  alt=""
                />
              );
              title_class = classes.active;
            }
          }

          if (index > 0) {
            if (index === active_point_in_time) {
              point = (
                <img
                  className={[classes.point_in_time, classes.active].join(" ")}
                  src={active_point_in_time_icon}
                  alt=""
                />
              );
              title_class = classes.active;
            } else if (index < active_point_in_time) {
              point = (
                <img
                  className={[
                    classes.point_in_time,
                    classes.rotate_clockwise,
                    classes.active
                  ].join(" ")}
                  src={active_point_in_time_icon}
                  alt=""
                />
              );
            } else {
              point = (
                <img
                  className={[classes.point_in_time].join(" ")}
                  src={point_in_time}
                  alt=""
                />
              );
              logo = (
                <img
                  className={[classes.logo].join(" ")}
                  src={time_point.title_logo.inactive}
                  alt=""
                />
              );
            }

            if (index <= active_point_in_time) {
              connector_classes.push(classes.active);
              title_class = classes.active;
            }
          }

          return (
            <Aux key={index}>
              <div
                className={connector_classes.join(" ")}
                style={connector_style}
              >
                <div className={classes.connector_moving} />
              </div>
              <div
                className={classes.point_in_time}
                onClick={() => timeline_stop_clicked(index)}
              >
                {logo}
                {point}
                <span className={[classes.title, title_class].join(" ")}>
                  {time_point.start_year}
                </span>
              </div>
            </Aux>
          );
        })}
        {next_arrow}
      </div>

      <div {...swipeHandlers} className={classes.preview_window}>
        <div className={classes.left}>
          <div className={info_container_classes.join(" ")}>
            <div className={classes.icon_container}>
              <img className={classes.side_logo} src={subtitle_icon} alt="" />
              <img
                className={classes.side_logo}
                src={organisation_icon}
                alt=""
              />
              <img className={classes.side_logo} src={location_icon} alt="" />
              <img className={classes.side_logo} src={date_icon} alt="" />
            </div>

            <div className={classes.label_container}>
              <span className={classes.side_label}>
                {points_in_time[active_point_in_time].subtitle}
              </span>
              <span className={classes.side_label}>
                {points_in_time[active_point_in_time].organisation}
              </span>
              <span className={classes.side_label}>
                {points_in_time[active_point_in_time].location}
              </span>
              <span className={classes.side_label}>
                {points_in_time[active_point_in_time].date}
              </span>
            </div>

            <img
              className={classes.org_logo}
              src={points_in_time[active_point_in_time].organisation_logo}
              alt=""
            />
          </div>
        </div>

        <div className={classes.right}>
          <div className={classes.description}>
            <h2>{points_in_time[active_point_in_time].title}</h2>
            {points_in_time[active_point_in_time].description}
          </div>
          <div className={classes.skills}>
            <div className={classes.scroll_container}>
              {points_in_time[active_point_in_time].skills.map(
                (skill, index) => {
                  return (
                    <Skill
                      logo={skill.logo}
                      text={skill.text}
                      key={index + skill.text}
                    />
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={classes.portfolio_button}
        onClick={props.scroll_to_portfolio}
      >
        <span className={classes.button_title}>Portfolio</span>
        <img className={[classes.button_arrow].join(" ")} src={arrow} alt="" />
      </div>
    </div>
  );
};

export default Timeline;
