import React from 'react';
import classes from './backdrop.css'

const Backdrop = (props) => {

    return (
        <div className={classes.backdrop} onClick={props.hide_preview}></div>
    )
}

export default Backdrop;
