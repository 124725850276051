import React, {useState} from "react";
import classes from "./portfolio.css";

// SuccessOnTap assets

import sot_cover from "../../assets/portfolio/successontap/sot.png";
import sot_1 from "../../assets/portfolio/successontap/1.png";
import sot_2 from "../../assets/portfolio/successontap/2.png";
import sot_3 from "../../assets/portfolio/successontap/3.png";
import sot_4 from "../../assets/portfolio/successontap/4.png";

import tokentrack_cover from "../../assets/portfolio/tokentrack/tokentrack_cover.png";
import tt_1 from "../../assets/portfolio/tokentrack/1.png";
import tt_2 from "../../assets/portfolio/tokentrack/2.png";
import tt_3 from "../../assets/portfolio/tokentrack/3.png";
import tt_4 from "../../assets/portfolio/tokentrack/4.png";

import ccm_cover from "../../assets/portfolio/ccm/ccm_cover.png";
import ccm_1 from "../../assets/portfolio/ccm/1.png";
import ccm_2 from "../../assets/portfolio/ccm/2.png";
import ccm_3 from "../../assets/portfolio/ccm/3.png";

import luberly_cover from "../../assets/portfolio/luberly/luberly_cover.png";
import luberly_1 from "../../assets/portfolio/luberly/luberly_1.png";
import luberly_2 from "../../assets/portfolio/luberly/luberly_2.png";
import luberly_3 from "../../assets/portfolio/luberly/luberly_3.png";
import luberly_4 from "../../assets/portfolio/luberly/luberly_4.png";

import heycar_cover from "../../assets/portfolio/heycar/heycar_cover.png";
import heycar_1 from "../../assets/portfolio/heycar/heycar_1.png";
import heycar_2 from "../../assets/portfolio/heycar/heycar_2.png";
import heycar_3 from "../../assets/portfolio/heycar/heycar_3.png";

import PortfolioPreview from "./portfolio_preview/portfolio_preview";
import Backdrop from "./backdrop/backdrop";

import Aux from "../../hoc/Aux";

//http://findmatthew.com/

const Portfolio = props => {
  const project_info_temp = [
    {
      name: "heycar",
      cover: heycar_cover,
      tech: "Backend Services",
      info_classes: [classes.info, classes.hide],
      button_classes: [classes.learn_more_button, classes.hide],
      cover_classes: [classes.cover_image],
      project_classes: [classes.project],
      tags: ["Python", "Django", "React", "Kubernetes", "AWS", "FastAPI"],
      show: true,
      subtitle: "Buying a car never felt so good",
      description:
        "Because let’s be honest: buying a used car can be a painful experience at the best times. Something had to change. And that’s why we started heycar.",
      image_list: [heycar_1, heycar_2, heycar_3],
      link: "https://www.heycar.co.uk"
    },
    {
      name: "Luberly",
      cover: luberly_cover,
      tech: "React / Python",
      info_classes: [classes.info, classes.hide],
      button_classes: [classes.learn_more_button, classes.hide],
      cover_classes: [classes.cover_image],
      project_classes: [classes.project],
      tags: ["JavaScript", "Python", "Django", "React", "Kubernetes", "GCP"],
      show: true,
      subtitle: "Manage all your plant's lubrication needs",
      description:
        "Luberly is modern lubrication software which brings clarity and structure to industrial sites lubrication and maintenance practices.",
      image_list: [luberly_1, luberly_2, luberly_3, luberly_4],
      link: "https://www.luberly.com"
    },
    {
      name: "SuccessOnTap",
      cover: sot_cover,
      tech: "Django / jQuery",
      info_classes: [classes.info, classes.hide],
      button_classes: [classes.learn_more_button, classes.hide],
      cover_classes: [classes.cover_image],
      project_classes: [classes.project],
      tags: ["jQuery", "JavaScript", "Python", "Django"],
      show: true,
      subtitle: "ACHIEVE YOUR PERSONAL GOALS",
      description:
        "SuccessOnTap is web-based personal goal management system I built using a Django/jQuery stack. Customers pay a $5 per month subscription fee for access.",
      image_list: [sot_1, sot_2, sot_3, sot_4],
      link: "https://www.successontap.io/"
    },
    {
      name: "TokenTrack",
      cover: tokentrack_cover,
      tech: "Django / React",
      info_classes: [classes.info, classes.hide],
      button_classes: [classes.learn_more_button, classes.hide],
      cover_classes: [],
      project_classes: [classes.project],
      tags: ["React", "JavaScript", "Python", "Django", "AWS"],
      show: true,
      subtitle: "THE SIMPLE BEAUTIFUL CYRPTO TRACKER",
      description:
        "TokenTrack is a cryptocurrency portfolio tracker I built using React with a Django Rest Framework API back end. It is also mobile responsive and can be added to mobile devices' homescreens.",
      image_list: [tt_1, tt_2, tt_3, tt_4],
      link: "https://www.tokentrack.xyz/"
    },
    {
      name: "MobilGuard CCM",
      cover: ccm_cover,
      tech: "Python",
      info_classes: [classes.info, classes.hide],
      button_classes: [classes.learn_more_button, classes.hide],
      cover_classes: [],
      project_classes: [classes.project],
      tags: ["Python"],
      show: true,
      subtitle: "ADVANCED MARINE ENGINE CONDITION MONITORING",
      description:
        "The MobilGard Cylinder Condition Monitoring features an advanced automation engine to analyse used oil testing data to help operators optimise feed rates and extend component life.",
      image_list: [ccm_1, ccm_2, ccm_3],
      link:
        "https://www.exxonmobil.com/en/marine/services/cylinder-condition-monitoring"
    }
  ];

  const tag_list_temp = [
    {
      name: "Python",
      button_active: false,
      filter_active: false
    },
    {
      name: "JavaScript",
      button_active: false,
      filter_active: false
    },
    {
      name: "React",
      button_active: false,
      filter_active: false
    },
    {
      name: "Django",
      button_active: false,
      filter_active: false
    },
    {
      name: "AWS",
      button_active: false,
      filter_active: false
    },
    {
      name: "Kubernetes",
      button_active: false,
      filter_active: false
    },
    {
      name: "FastAPI",
      button_active: false,
      filter_active: false
    },
    {
      name: "GCP",
      button_active: false,
      filter_active: false
    }
  ];

  const [project_info, set_project_info] = useState(project_info_temp);
  const [tag_list, set_tag_list] = useState(tag_list_temp);
  const [show_preview, set_show_preview] = useState(false);
  const [preview_data, set_preview_data] = useState(project_info_temp[0]);

  const project_hover_on_handler = index => {
    const current_project = {
      ...project_info[index]
    };

    current_project.info_classes = [classes.info];
    current_project.button_classes = [classes.learn_more_button];
    current_project.cover_classes = [classes.transparent];

    const project_list = [...project_info];
    project_list[index] = current_project;

    set_project_info(project_list);
  };

  const project_hover_off_handler = index => {
    const current_project = {
      ...project_info[index]
    };

    current_project.info_classes = [classes.info, classes.hide];
    current_project.button_classes = [classes.learn_more_button, classes.hide];
    current_project.cover_classes = [];

    const project_list = [...project_info];
    project_list[index] = current_project;

    set_project_info(project_list);
  };

  const filter_click_handler = index => {
    const tag = tag_list[index];

    if (tag.filter_active === true) {
      filter_click_off(index);
    } else {
      filter_click_on(index);
    }
  };

  const filter_click_on = index => {
    const project_list = [...project_info];

    const tag_name = tag_list[index].name;

    project_info.map((project, index) => {
      const current_project = {
        ...project
      };

      if (!project.tags.includes(tag_name)) {
        if (!current_project.project_classes.includes(classes.hidden)) {
          current_project.project_classes.push(classes.hidden);
        }

        project_list[index] = current_project;
      }

      return null;
    });

    set_project_info(project_list);

    const tag_list_copy = [...tag_list];

    const current_tag = {
      ...tag_list[index]
    };

    current_tag.button_active = true;
    current_tag.filter_active = true;

    tag_list_copy[index] = current_tag;

    set_tag_list(tag_list_copy);
  };

  const filter_click_off = index => {
    const project_list = [...project_info];
    const tag_name = tag_list[index].name;

    // create list of active filters (excluding current tag as we just clicked to deactivate)
    let active_tags = [];
    tag_list.map((tag, index) => {
      if (tag.filter_active && tag.name !== tag_name) {
        active_tags.push(tag.name);
      }
      return null;
    });

    project_info.map((project, index) => {
      // create copy of current project for state modification
      const current_project = {
        ...project
      };

      // check whether this project has any tags which are still active before showing again
      let still_active = false;
      project.tags.map(project_tag => {
        active_tags.map(active_tag => {
          if (project_tag === active_tag) {
            still_active = true;
          }
          return null;
        });
        return null;
      });
      // if none selected we need to show all projects
      if (active_tags.length === 0) {
        still_active = true;
      }

      // unhide project if it doesnt inlude recently deactivated filter but still has at least one active tag
      if (!project.tags.includes(tag_name) && still_active) {
        if (current_project.project_classes.includes(classes.hidden)) {
          const index = current_project.project_classes.indexOf(classes.hidden);
          if (index > -1) {
            current_project.project_classes.splice(index, 1);
          }
        }

        project_list[index] = current_project;
      }

      return null;
    });

    set_project_info(project_list);

    const tag_list_copy = [...tag_list];

    const current_tag = {
      ...tag_list[index]
    };

    current_tag.button_active = false;
    current_tag.filter_active = false;

    tag_list_copy[index] = current_tag;

    set_tag_list(tag_list_copy);
  };

  const execute_filter_status = () => {
    console.log("filter_status = ", tag_list);

    const project_list = [...project_info];

    project_list.map((project, index) => {
      let current_project = {...project_info[index]};
      current_project.project_classes = [classes.project];

      project_list[index] = current_project;
      return null;
    });

    tag_list.map((tag, index) => {
      console.log("workong on tag = " + tag.name);

      if (tag.filter_active === false) {
        console.log("filter is active");
        project_list.map((project, index) => {
          console.log("checking project = " + project.name);

          let current_project = {...project_info[index]};

          if (current_project.tags.includes(tag.name)) {
            console.log("    project contains tag - adding hidden class");
            if (!current_project.project_classes.includes(classes.hidden)) {
              current_project.project_classes.push(classes.hidden);
            }
          }

          project_list[index] = current_project;
          return null;
        });
      }

      return null;
    });

    console.log("setting state");
    set_project_info(project_list);
  };

  const hide_preview = () => {
    set_show_preview(false);
  };

  const show_preview_func = index => {
    set_preview_data(project_info[index]);
    set_show_preview(true);
  };

  let preview_elements = (
    <Aux>
      <PortfolioPreview hide={true} data={preview_data} />
    </Aux>
  );

  if (show_preview) {
    preview_elements = (
      <Aux>
        <PortfolioPreview hide={false} data={preview_data} />
        <Backdrop hide_preview={hide_preview} />
      </Aux>
    );
  }

  return (
    <Aux>
      <div className={classes.portfolio} ref={props.portfolio_anchor}>
        <h2 className={classes.section_heading} onClick={execute_filter_status}>
          PROJECTS
        </h2>

        <div className={classes.project_filter_container}>
          {tag_list.map((tag, index) => {
            return tag.button_active ? (
              <button
                className={[classes.filter_button, classes.filter_active].join(
                  " "
                )}
                key={index}
                onClick={() => filter_click_handler(index)}
              >
                {tag.name} &times;
              </button>
            ) : (
              <button
                className={classes.filter_button}
                key={index}
                onClick={() => filter_click_handler(index)}
              >
                {tag.name} &times;
              </button>
            );
          })}
        </div>

        <div className={classes.project_container}>
          {project_info.map((project, index) => {
            return (
              <div
                className={project.project_classes.join(" ")}
                key={index}
                onMouseOver={() => project_hover_on_handler(index)}
                onMouseOut={() => project_hover_off_handler(index)}
                onClick={() => show_preview_func(index)}
              >
                <div className={project.info_classes.join(" ")}>
                  <span className={classes.title}>{project.name}</span>
                  <span className={classes.tech}>{project.tech}</span>
                </div>
                <img
                  src={project.cover}
                  className={project.cover_classes.join(" ")}
                  alt=""
                />
                <button className={project.button_classes.join(" ")}>
                  Learn More
                </button>
              </div>
            );
          })}
        </div>
      </div>
      {preview_elements}
    </Aux>
  );
};

export default Portfolio;
