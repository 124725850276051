import React from 'react';
import classes from './skill.css'


const Skill = (props) => {

  return (
    <div className={classes.skill}>
      <img className={classes.logo} src={props.logo} alt=""/>
      <span className={classes.name}>{props.text}</span>
    </div>
  )
}

export default Skill;
