import React, { Component } from "react";
import classes from "./App.css";

import Layout from "./components/layout/layout";
import Homepage from "./containers/Homepage/Homepage";
import Portfolio from "./components/portfolio/portfolio";

import ScrollToTop from "./components/scroll_to_top/scroll_to_top";

class App extends Component {
  // mabe all this logic should be in layout?

  state = {
    show_scroll_to_top_button: false
  };

  constructor(props) {
    super(props);
    this.portfolio_anchor = React.createRef();
  }

  scroll_to_portfolio = () => {
    window.scrollTo(0, this.portfolio_anchor.current.offsetTop);
  };

  show_scroll_to_top_button = event => {
    this.setState({ show_scroll_to_top_button: true });
    window.removeEventListener("scroll", this.test);
  };

  componentDidMount() {
    window.addEventListener("scroll", this.show_scroll_to_top_button);
  }

  scroll_to_top = () => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.show_scroll_to_top_button);

    setTimeout(() => {
      this.setState({ show_scroll_to_top_button: false });
    }, 1000); // bit hacky using set timeout but will do for now
  };

  render() {
    return (
      <div className={classes.App}>
        <Layout scroll_to_portfolio={this.scroll_to_portfolio}>
          <Homepage scroll_to_portfolio={this.scroll_to_portfolio} />
          <Portfolio portfolio_anchor={this.portfolio_anchor} />
          <ScrollToTop
            show={this.state.show_scroll_to_top_button}
            scroll_to_top={this.scroll_to_top}
          />
        </Layout>
      </div>
    );
  }
}

export default App;
