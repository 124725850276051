import React from 'react';
import classes from './footer.css'

const Footer = () => {
  return (
    <div className={classes.footer}>
        <span className={classes.name}>Built by me &copy; 2019</span>
    </div>
  )
}

export default Footer;
